<template>
    <v-container fluid>
      <v-row>
        <v-col col="12" md="12" lg="12" outlined>
          <v-card class="pa-4 rounded-xl">
            <v-card-title class="justify-left d-flex flex-row">
              <p>Usuarios</p>
              <v-spacer></v-spacer>
              <v-btn class="ma-3 rounded-lg text-capitalize" dense color="primary" :to="{ name: 'empresa-usuarios-add' }">
                Agregar Usuario
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12" lg="12">
                    <table-user-company></table-user-company>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
  
          </v-card>
        </v-col>
  
      </v-row>
    </v-container>
  </template>
  
  <script>
  import TableUserCompany from "@/components/tables/TableUserCompany.vue";
  export default {
    name: "empresa-usuarios-index",
    components: { TableUserCompany },
    data: () => ({}),
  };
  </script>
  
  <style>
  </style>