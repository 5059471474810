<template>
  <v-row align="center" class="list">
    <v-col cols="12" sm="12">
      <v-data-table :mobile-breakpoint="0" class="iconTable" :headers="headers" :items="users.data" disable-pagination
        :hide-default-footer="true" :loading="fetchingData" :loading-text="'Cargando... Espere por favor'"
        :no-data-text="'datos no disponibles'" item-key="_id" hide-default-header>
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header" style="background-color: #299F7E;">
            <tr>
              <template v-for="header in headers">
                <th :key="header.value" :class="header.align">
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: white">
                        {{ header.text }}
                      </h2>
                    </v-col>
                  </v-row>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #[`item.name`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.name }}
          </td>
        </template>
        <template #[`item.last_name`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.last_name }}
          </td>
        </template>
        <template #[`item.alias`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.alias }}
          </td>
        </template>
        <template #[`item.document_number`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.document_number }}
          </td>
        </template>
        <template #[`item.email`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.email }}
          </td>
        </template>
        <template #[`item.phone`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.phone }}
          </td>
        </template>
        <template #[`item.actions`]="{ item }">
          <td :style="
            users.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            <v-btn icon color="primary" :to="'/empresa-usuarios/edit/' + item.id">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" @click="clickDeleteUser(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </template>

      </v-data-table>
    </v-col>

    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex justify-end">
          <v-pagination v-model="page" :length="users.last_page" total-visible="7" next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left" @input="handlePageChange" circle></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text2 }}</span>
    </v-snackbar>
  </v-row>
</template>
  
  <script>
import { mapState, mapActions } from "vuex";
export default {
  name: "table-user-company",
  watch: {
    text: function (value) {
      this.getFetch();
    },
  },
  data: () => ({
    headers: [
      { text: "Nombre", value: "name", width: 150, sortable: false },
      { text: "Apellido", value: "last_name", width: 200, sortable: false },
      { text: "Alias", value: "alias", sortable: false },
      { text: "DNI", value: "document_number", sortable: false },
      { text: "Correo", value: "email", sortable: false },
      { text: "Telefono", value: "phone", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    snackbar: false,
    color: "",
    text2: "",
    //   loading: false
  }),
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      fetchingData: (state) => state.fetchingData,
    }),
    text() {
      return this.$route.query.text;
    },
  },
  methods: {
    ...mapActions(["fetchUsersCompany", "deleteCompanyUser"]),
    getRequestParams(page, pageSize) {
      let params = {};

      if (this.text) {
        params["search"] = this.text;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["per_page"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text2 = text;
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize);
      this.fetchUsersCompany({ params: params });
    },
    clickDeleteUser(id) {
      this.deleteCompanyUser({ id: id }).then(({data}) => {
        this.mensaje(true, "success", data.message, 5000);
        this.getFetch();
      }).catch((error) => {
        this.mensaje(true, "error", error.message, 5000);
      });
    }
  },
  created() {
    this.getFetch();
  },
};
</script>
  
  <style>
  </style>